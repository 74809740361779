
import React from 'react'
import Popup from '../Popup'
import SessionManager from '../../code/SessionManager'
import Button from '../../shared/Button'
import { showBusy } from '../../code/AlertUtils'
import JSFileManager, { JSFile } from 'js-file-manager'
import Analytics from '../../code/Analytics'
import qrcode from 'qrcode'
import jwtDecode from 'jwt-decode'
import JSAlert from 'js-alert'
import BLOCKv from '../../code/BLOCKv'
import Task from '../../code/Task'

export default class TaskLogPopup extends Popup {

    constructor() {
        super()

        // Setup state
        this.state = {}
        this.state.taskID = ''
        this.state.logs = []
        this.state.task = null
        this.state.campaign = null
        this.state.campaignLoading = true
        this.state.campaignError = null

    }

    async componentDidMount() {

        // Send analytics
        Analytics.screen("Task Log Popup")

        // Wait until we have a valid task ID
        let taskID = await this.props.taskID
        this.setState({ taskID })

        // Add log entry listener
        this.detachLogListener = SessionManager.db.collection('tasks').doc(taskID).collection('logs')
            .where('owner', '==', SessionManager.firebaseUserID)
            .orderBy('date', 'desc')
            .limit(40)
            .onSnapshot(
                e => this.setState({ logs: e.docs }),
                err => console.error("Firebase error: ", err
            ))

        // Add document listener
        this.detachDocumentListener = SessionManager.db.collection('tasks').doc(taskID)
            .onSnapshot(
                e => {
                    this.setState({ action: e.get('action'), task: e })
                    this.loadCampaign(e)
                },
                err => console.error("Firebase error: ", err
            ))

    }

    async loadCampaign(task) {

        try {
        
            // Get campaign
            let campaignRef = task.get('campaign')
            if (!campaignRef)
                return this.setState({ campaignLoading: false, campaignError: null })

            // Fetch campaign
            let campaign = await campaignRef.get()
            this.setState({ campaignLoading: false, campaignError: null, campaign })
            console.log(campaign)

        } catch (err) {

            // Failed to load campaign info
            this.setState({ campaignLoading: false, campaignError: err, campaign: null })

        }

    }

    componentWillUnmount() {

        // Detach listeners
        if (this.detachDocumentListener) this.detachDocumentListener()
        this.detachDocumentListener = null

    }

    render() {

        return <div style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'stretch' }}>

            {/* Top bar */}
            <div style={{ flex: '0 0 auto', backgroundColor: '#373b40', display: 'flex', alignItems: 'center' }}>
                <Button text='Download CSV' style={{ marginRight: 20, backgroundColor: 'transparent', color: '#AAA' }} onClick={e => this.downloadCSV()} />
                <div style={{ flex: '1 1 auto' }} />
                <Button text='Close' style={{ marginRight: 20, backgroundColor: 'transparent', color: '#AAA' }} onClick={e => this.close()} />
            </div>

            {/* Content area */}
            <div style={{ flex: '1 1 auto', backgroundColor: '#272b30', padding: '20px', overflow: 'hidden', fontSize: 13, fontWeight: 'bold', lineHeight: '1.5' }}>
                {this.state.logs.map(log => {
                    let color = '#AAA'
                    let target = log.get('target')
                    let text = log.get('text')
                    let type = log.get('type')
                    if (type == 'success') color = '#126314'
                    if (type == 'failed') color = '#d81717'
                    if (type == 'warn') color='#873e14'
                    if (target) text = `${target}: ${text}`
                    return <div key={log.id} style={{ color }}>{text}</div>
                })}
            </div>

            {/* Output campaign info */}
            {this.renderCampaignInfo()}

            {/* Action bar, if there's a custom action */}
            {this.state.action ? 
                <div style={{ flex: '0 0 auto', backgroundColor: '#373b40', display: 'flex', alignItems: 'center' }}>
                    <div style={{ flex: '1 1 auto' }} />
                    <Button text={this.state.action.name} style={{ marginRight: 20, backgroundColor: 'transparent', color: '#AAA' }} onClick={e => this.performCustomAction()} />
                    <div style={{ flex: '1 1 auto' }} />
                </div> 
            : null}

        </div>

    }

    renderCampaignInfo() {

        // Checking campaign status, if needed
        if (this.state.campaignLoading) return <div style={{ backgroundColor: '#373b40', color: '#AAA', fontSize: 12, padding: 16, textAlign: 'center' }}>
            <img src={require('./Spinner-1s-200px.apng')} style={{ display: 'inline-block', height: 24, verticalAlign: 'middle', paddingRight: 8, paddingBottom: 3 }} />
            Loading campaign...
        </div>

        // Checking campaign status, if needed
        if (this.state.campaignError) return <div style={{ backgroundColor: '#373b40', color: '#AAA', fontSize: 12, padding: 16, textAlign: 'center' }}>
            {this.state.campaignError.message}
        </div>

        // If no campaign, stop
        if (!this.state.campaign)
            return <></>

        // If campaign is an acquire campaign
        let campaignType = this.state.campaign.get('type')
        if (campaignType == 'acquire' || campaignType == 'pool') return <div style={{ backgroundColor: '#373b40', color: '#AAA', fontSize: 12, padding: 16, textAlign: 'left' }}>
                    
            <div style={{ display: 'flex', marginBottom: 8 }}>
                <div style={{ width: 100, flex: '0 0 auto' }}>Validity</div>
                <div style={{ width: 100, flex: '1 1 auto', color: '#FFF' }}>
                    {this.getJWTExpiryTime(this.state.campaign.get('bvRefreshToken'))}
                    <a style={{ color: '#08F', cursor: 'pointer', fontWeight: 'bold', paddingLeft: 16 }} onClick={e => this.extendCampaignToken(this.state.campaign)}>Extend</a>
                </div>
            </div>
            
            <div style={{ display: 'flex' }}>
                <div style={{ width: 100, flex: '0 0 auto' }}>Distributed</div>
                <div style={{ width: 100, flex: '1 1 auto', color: '#FFF' }}>
                    {this.state.campaign.get('emitted')} out of {this.state.campaign.get('max')} total
                    <a style={{ color: '#08F', cursor: 'pointer', fontWeight: 'bold', paddingLeft: 16 }} onClick={e => this.updateMaxVatoms()}>Change Maximum</a>
                </div>
            </div>

        </div> 

        // Unknown type of campaign
        return <></>

    }

    /** Called when the user presses "Change Maximum" on the campaign info */
    updateMaxVatoms() { showBusy(async e => {

        // Get current max
        let amount = this.state.campaign.get('max')
        let newAmount = prompt('Enter the maximum amount of vAtoms which can be acquired by users through this campaign:', amount)
        newAmount = parseInt(newAmount)
        if (!newAmount)
            return

        // Update max field in the database
        await SessionManager.db.collection('campaigns').doc(this.state.campaign.id).set({
            max: newAmount
        }, { merge: true })

        // Update UI
        await this.loadCampaign(this.state.task)

    })}

    /** Returns a user-readable string describing how long until the specified JWT token expires */
    getJWTExpiryTime(token) {

        try {

            // Decode it
            let info = jwtDecode(token)

            // Get expiry date
            let expiryDate = new Date(info.exp * 1000)
            if (!expiryDate.getTime())
                throw new Error("Invalid date in JWT token.")

            // Check if date is in the past
            if (expiryDate.getTime() < Date.now())
                throw new Error("JWT token has already expired.")

            // Get time difference in minutes
            let diff = Math.floor((expiryDate.getTime() - Date.now()) / 1000 / 60)
            if (diff < 60)
                return diff == 1 ? "1 minute" : diff + ' minutes'

            // Get time difference in hours
            diff = Math.floor((expiryDate.getTime() - Date.now()) / 1000 / 60 / 60)
            if (diff < 24)
                return diff == 1 ? "1 hour" : diff + ' hours'

            // Get time difference in days
            diff = Math.floor((expiryDate.getTime() - Date.now()) / 1000 / 60 / 60 / 24)
            return diff == 1 ? "1 day" : diff + ' days'

        } catch (err) {

            // Invalid token
            console.warn(err)
            return "Expired"

        }

    }

    /** Called when the user presses the Extend button next to the campaign expiry time. This should attach the user's
     * current refresh token to the campaign.
     */
    extendCampaignToken() { showBusy(async e => {

        // Fetch old refresh token
        let oldToken = this.state.campaign.get('bvRefreshToken')
        let oldTokenInfo = { exp: 0 }
        try { 
            oldTokenInfo = jwtDecode(oldToken) 
        } catch (err) { 
            console.warn(err) 
        }
        let oldTokenExpiry = oldTokenInfo.exp

        // Get new refresh token
        let newToken = BLOCKv.store.refreshToken
        let newTokenInfo = jwtDecode(newToken)
        let newTokenExpiry = newTokenInfo.exp
        if (newTokenExpiry <= oldTokenExpiry) {

            // Current token is the same as the one used to create the campaign. Ask the user to login again to get a new one.
            throw new Error('Please log out and log in again to get a longer session token from the BLOCKv system.')

        }

        // Set it
        await SessionManager.db.collection('campaigns').doc(this.state.campaign.id).set({
            bvRefreshToken: newToken
        }, { merge: true })

        // Add a log entry
        let task = new Task()
        task.id = this.props.taskID
        task.log('', 'info', `Extended campaign validity to ${this.getJWTExpiryTime(newToken)}.`)

        // Update UI
        await this.loadCampaign(this.state.task)

    })}

    /** Called when the user wants to download the CSV. */
    downloadCSV() { showBusy(async e => {

        // Fetch all documents
        let queryResult = await SessionManager.db.collection('tasks').doc(this.state.taskID).collection('logs')
            .where('owner', '==', SessionManager.firebaseUserID)
            .orderBy('date', 'desc')
            .get()

        // Create CSV. Write out header
        let csv = `"Date","Target","Type","Text"\n`

        // Go through each log entry
        for (let entry of queryResult.docs) {

            // Get fields
            let date = entry.get('date').toDate().toISOString()
            let target = entry.get('target') || ''
            let type = entry.get('type') || ''
            let text = entry.get('text') || ''

            // CSV-escape characters
            date = date.replace(/"/g, "\\\"")
            target = target.replace(/"/g, "\\\"")
            type = type.replace(/"/g, "\\\"")
            text = text.replace(/"/g, "\\\"")

            // Write CSV out
            csv += `"${date}","${target}","${type}","${text}"\n`

        }

        // Save file
        let taskInfo = await SessionManager.db.collection('tasks').doc(this.state.taskID).get()
        let taskName = taskInfo.get('name')
        let taskDate = queryResult.docs[0].get('date').toDate().toISOString()
        new JSFile(csv, `${taskName} - ${taskDate}.csv`).save()

        // Send analytics
        Analytics.event("ExportCSV", { count: queryResult.docs.length })

    })}

    /**
     * Performs the task's custom action
     */
    async performCustomAction() {

        // Check event type
        if (this.state.action.type == 'copy-text') {

            // Do copy with virtual element
            let elem = document.createElement('textarea')
            elem.value = this.state.action.data
            elem.style.cssText = 'position: absolute; left: -9999px; '
            document.body.appendChild(elem)
            elem.select()
            document.execCommand('copy')
            document.body.removeChild(elem)

            // Done
            alert('It has been copied to your clipboard.')

        } else if (this.state.action.type == 'generate-qr') {

            // Load images
            let logo = await loadImage(require('../../routes/Merchant/do-qr-icon.png'))

            // Create QR code
            let canvas = await new Promise((success, fail) => qrcode.toCanvas(this.state.action.data, {
                width: 1024, height: 1024, error: 'H'
            }, (err, canvas) => err ? fail(err) : success(canvas)))

            // Get graphics context
            let ctx = canvas.getContext('2d')

            // Draw logo
            let logoSize = 360
            ctx.drawImage(logo, 1024/2 - logoSize/2, 1024/2 - logoSize/2, logoSize, logoSize)

            // Fetch image from canvas
            let blob = await new Promise(cb => canvas.toBlob(cb))

            // Save
            new JSFile(blob, "QRCode.png").save()

        }

    }

}


// Load an Image from a url
function loadImage(url) {
    return new Promise((success, fail) => {
        let img = new Image()
        img.src = url
        img.onload = e => success(img)
        img.onerror = e => fail(new Error('Unable to load image.'))
    })
}