
import React from 'react'

export default props => <div style={{ position: 'absolute', top: 0, left: 0, width: '100%', minHeight: '100%', overflow: 'hidden' }}>

    {/* Background */}
    <div style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '760px', backgroundSize: 'cover', backgroundPosition: 'center', backgroundImage: 'url(' + require('./stock.jpg') + ')' }} />

    {/* Slopey divider */}
    <div style={{ backgroundColor: '#EEF2F6', position: 'absolute', top: 680, left: -40, width: 'calc(100% + 80px)', height: 180, transform: 'rotate(3deg)' }} />

    {/* Content */}
    {props.children}
    
</div>