
import BLOCKv from './BLOCKv'
import firebase from 'firebase/app'
import 'firebase/auth'
import 'firebase/firestore'
import { showBusy } from './AlertUtils'

import AccountUpgradePopup from '../popups/AccountUpgradePopup'
import Analytics from './Analytics';

/**
 *  Utility function to extract URL params from a URL
 */
function extractParams(url) {

    // Put each keyval pair into the params object
    var params = {}
    url.split(/[&#?]/).slice(1).map(keyval => keyval.split('=')).map(keyval => {
        params[keyval[0]] = keyval.length == 1 ? true : decodeURIComponent(keyval[1])
    })

    // Done
    return params

}

/**
 *  Manages the user session shared between BLOCKv and Firebase.
 */
export default new class SessionManager {

    /**
     *  This should be called before the app is started. It makes sure the Firebase user session is in sync with the
     *  BLOCKv user session.
     *
     *  @returns {Promise} Resolves once the user sessions are both stable.
     */
    async setup() {

        // Setup firebase
        firebase.initializeApp({
            apiKey: "AIzaSyC_vn-85DK09FcVdMrb1OZVXQ8tEsqeA9Y",
            authDomain: "digital-objects-io.firebaseapp.com",
            projectId: "digital-objects-io"
        });

        // Setup database
        this.db = firebase.firestore()

        // Catch BLOCKv errors
        try {

            // Check if we are logged into BLOCKv
            if (!BLOCKv.UserManager.isLoggedIn)
                return

            // Ensure we are logged in, by fetching the user's profile
            await BLOCKv.UserManager.getCurrentUser()

            // We are logged in. Get the access token.
            console.log(`[SessionManager] BLOCKv is logged in, logging in to Firebase...`)
            var accessToken = BLOCKv.store.token

            // Convert to firebase token and login
            var firebaseToken = await this.blockvToFirebaseToken(accessToken)
            await firebase.auth().signInWithCustomToken(firebaseToken)

        } catch (err) {

            // Unable to log in!
            console.warn(`[SessionManager] Unable to sync BLOCKv and Firebase user sessions! `, err)

        }

    }

    /**
     *  Returns true if we have a valid refresh token and  avalid database connection
     */
    get loggedIn() {
        return BLOCKv.UserManager.isLoggedIn && firebase.auth().currentUser
    }

    /**
     *  Returns the current user's firebase ID
     */
    get firebaseUserID() {
        return firebase.auth().currentUser && firebase.auth().currentUser.uid
    }

    /**
     *  This handles the OAuth response catching. Call this on app startup, and do not load your app if this returns true.
     *
     *  @returns {boolean} True if the app should shut itself down and not load.
     */
    check() {

        // Check for callback handle request
        if (location.href.indexOf('oauth_callback_handler=1') == -1)
            return false

        // We have a response! Send it to the main app instance
        window.opener.postMessage({
            action: 'oauth-response',
            url: location.href
        }, location.origin)

        // Close the window
        window.close()

        // Done
        return true

    }

    /**
     *  Begin the login process.
     *
     *  @param {bool} skipUpgrade If true, will not show the upgrade popup for non-publisher accounts
     *  @returns {Promise<void>} A promise which resolves when login completes.
     */
    async login(skipUpgrade) {

        // Login
        let success = await BLOCKv.UserManager.loginOAuthPopup()
        if (!success)
            throw new Error("The login was interrupted.")

        // Get a Firebase token from the provided access token
        await BLOCKv.client.checkToken()
        var firebaseToken = await this.blockvToFirebaseToken(BLOCKv.store.token)

        // Login to firebase
        await firebase.auth().signInWithCustomToken(firebaseToken)

        // Remove cached info
        this.walletID = null

        // Get user profile, check if they're a publisher
        let profile = await BLOCKv.UserManager.getCurrentUser()
        if (!profile.isAdmin && !skipUpgrade) {

            // Notify the user that their account is not a pubblisher
            AccountUpgradePopup.show()
            
        }

        // Send analytics
        Analytics.login('oauth')

    }

    /**
     *  Converts a BLOCKv access token into a Firebase custom auth token.
     *
     *  @private
     */
    async blockvToFirebaseToken(accessToken) {

        let response = await fetch("https://us-central1-digital-objects-io.cloudfunctions.net/bvTokenToFirebase", {
            method: 'POST',
            body: JSON.stringify({
                accessToken: accessToken
            })
        })

        // Get data
        response = await response.json()
        if (response.errorText)
            throw new Error(response.errorText)

        // Return response
        return response.firebaseToken

    }

    /** Logout. @returns {Promise} */
    async logout() {

        // Sign out of Firebase
        await firebase.auth().signOut()

        // Log out of the BLOCKv SDK
        await BLOCKv.UserManager.logout()

    }

    /** Get user balances */
    async getBalances() {
        return await BLOCKv.client.request('GET', '/v1/user/balance', '', true)
    }

    /** Get the user's VEE count */
    async getVEE() {

        // Catch errors
        try {

            // Get wallet balance
            let balanceInfo = await this.getBalances()
            return parseFloat(balanceInfo.find(b => b.coin == 'vee')?.balance) || 0
            
        } catch (err) {
            console.error(err)
            return 0
        }

    }

    /** Get the user's USD count */
    async getUSD() {

        // Catch errors
        try {

            // Get wallet balance
            let balanceInfo = await this.getBalances()
            return parseFloat(balanceInfo.find(b => b.coin == 'usd')?.balance) || 0
            
        } catch (err) {
            console.error(err)
            return 0
        }

    }

}
