import Blockv from '@blockv/sdk' //, { VatomView } from '@blockv/sdk/face'
// import Face3D from '@blockv/3d-face'

let bv = new Blockv({
    "appID" : "a87083f6-2da1-464b-bbe2-847ad254a19a",
    "server" : "https://api.blockv.io",
    "websocketAddress" : "wss://newws.blockv.io"
})

export default bv

// Register faces
// VatomView.registerFace(Face3D)

/**
 *  This function adds the JWT to the end of the CDN urls to allow access using the new method.
 */
// bv.encodeAssetProvider = function(urlString) {

//     try {

//         // Only if on CDN
//         let url = new URL(urlString)
//         if (url.hostname != 'cdn.blockv.io')
//             return urlString

//         // Append access token
//         return urlString + "?jwt=" + bv.store.token

//     } catch (err) {

//         // Invalid URL
//         console.warn("Invalid URL, can't encode asset provider: ", urlString, err)
//         return urlString

//     }

// }
