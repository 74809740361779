
import SessionManager from './SessionManager'
import BLOCKv from './BLOCKv'

/**
 *  This class builds the vAtom structure on the BLOCKv system from the Firebase description
 */
export default class VatomBuilder {

    /**
     *  Build the vatom from the firebase Vatom doc and return the template variation ID.
     */
    static async build(vatomDoc, sample) {

        // Check if already setup
        let id = sample ? vatomDoc.get('sampleVariationID') : vatomDoc.get('variationID')
        if (id)
            return id

        // Get user's fqdn
        let userInfo = await BLOCKv.UserManager.getCurrentUser()

        // Build private section
        var privateSection = {
            user_data: {}
        }
        if (vatomDoc.get('privateSection'))
            Object.assign(privateSection, JSON.parse(vatomDoc.get('privateSection')))

        privateSection.creator_tool = "DigitalObjects.io"
        privateSection.signature = ""

        // Add AR anchor fields if this vatom can be dropped and is 3D
        let canBeAnchored = vatomDoc.get('modelURL') && vatomDoc.get('canDrop')
        if (canBeAnchored) {
            privateSection.ar_anchor_id = ''
            privateSection.ar_scale = 1
        }

        // Get custom faces
        let customFaces = []
        try {
            customFaces = JSON.parse(vatomDoc.get('customFaceCode') || '[]') || []
        } catch (err) {}

        // Get custom resources
        let customResources = {}
        try {
            customResources = JSON.parse(vatomDoc.get('customResources') || '{}') || {}
        } catch (err) {}

        // Build template payload
        let templatePayload = {
            template: `${userInfo.pubFqdn}::${sample ? 'DOSample' : 'DO'}-${vatomDoc.id}-v${vatomDoc.get('version')}-${Math.floor(Date.now() / 1000)}`,
            public: true,
            cloneable: !!vatomDoc.get('canClone'),
            unpublished: sample ? true : false,
            vatom: {
                "vAtom::vAtomType": {
                    root_type: "vAtom::vAtomType",
                    redeemable: false,
                    title: vatomDoc.get('name') || '',
                    description: vatomDoc.get('description') || '',
                    category: vatomDoc.get('category') || '',
                    transferable: !!vatomDoc.get('canTransfer'),
                    acquirable: !!(vatomDoc.get('canAcquire') || vatomDoc.get('canAcquirePubVariation')),
                    redeemable: !!vatomDoc.get('canRedeem'),
                    states: [
                      	{
                          	name: "Activated",
                          	value: {
                              type: "boolean",
                              value: "true"
                          	}
                      	}
                	],
                    resources: []
                },
                private: privateSection
            }
        }

        // Add Ethereum section if necessary
        if (vatomDoc.get('isEthereum')) templatePayload.eth = {
            network: 'mainnet',
            fields: {}
        }

        // Add image resource
        templatePayload.vatom["vAtom::vAtomType"].resources.push({
            name: "ActivatedImage",
            resourceType: "ResourceType::Image::PNG",
            value: {
                resourceValueType: "ResourceValueType::URI",
                value: vatomDoc.get('iconURL')
            }
        })

        // If 3D, add the scene resource
        if (vatomDoc.get('modelURL')) templatePayload.vatom["vAtom::vAtomType"].resources.push({
            name: "Scene",
            resourceType: "ResourceType::3D::Scene",
            value: {
                resourceValueType: "ResourceValueType::URI",
                value: vatomDoc.get('modelURL')
              }
        })

        // If we have a card image, add it
        if (vatomDoc.get('cardImageURL')) templatePayload.vatom["vAtom::vAtomType"].resources.push({
            name: "CardImage",
            resourceType: "ResourceType::Image::PNG",
            value: {
                resourceValueType: "ResourceValueType::URI",
                value: vatomDoc.get('cardImageURL')
            }
        })

        // Add custom resources
        for (let name of Object.keys(customResources)) {

            // Detect resource type
            let url = customResources[name]
            let urlLower = url.toLowerCase()
            let type = "ResourceType::3D::Scene"
            if (urlLower.includes(".gif"))          type = "ResourceType::Image::GIF"
            else if (urlLower.includes(".png"))     type = "ResourceType::Image::PNG"
            else if (urlLower.includes(".jpg"))     type = "ResourceType::Image::JPEG"
            else if (urlLower.includes(".txt"))     type = "ResourceType::Text::Plain"
            else if (urlLower.includes(".htm"))     type = "ResourceType::Text::HTML"
            else if (urlLower.includes(".html"))    type = "ResourceType::Text::HTML"
            else if (urlLower.includes(".js"))      type = "ResourceType::Code::Javascript"
            else if (urlLower.includes(".go"))      type = "ResourceType::Code::Golang"
            else if (urlLower.includes(".mp3"))     type = "ResourceType::Audio::MPEG"
            else if (urlLower.includes(".m4a"))     type = "ResourceType::Audio::MPEG"
            else if (urlLower.includes(".wav"))     type = "ResourceType::Audio::WAV"
            else if (urlLower.includes(".avi"))     type = "ResourceType::Video::AVI"
            else if (urlLower.includes(".mp4"))     type = "ResourceType::Video::MPEG"
            else if (urlLower.includes(".mpg"))     type = "ResourceType::Video::MPEG"
            else if (urlLower.includes(".mkv"))     type = "ResourceType::Video::MPEG"
            else if (urlLower.includes(".qt"))      type = "ResourceType::Video::Quicktime"
            else if (urlLower.includes(".asf"))     type = "ResourceType::Video::ASF"
            else if (urlLower.includes(".glb"))     type = "ResourceType::3D::Scene"
            else if (urlLower.includes(".gltf"))    type = "ResourceType::3D::Scene"

            // Remove existing resource with the same name, if any
            templatePayload.vatom["vAtom::vAtomType"].resources = templatePayload.vatom["vAtom::vAtomType"].resources.filter(r => r.name != name)

            // Add resource
            templatePayload.vatom["vAtom::vAtomType"].resources.push({
                name,
                resourceType: type,
                value: {
                    resourceValueType: "ResourceValueType::URI",
                    value: url
                }
            })

        }

        // Register template
        await BLOCKv.client.request("POST", "/v1/templates", templatePayload, true)

        // Register the plain image face
        if (!customFaces.find(f => f.constraints && f.constraints.view_mode == 'icon')) await BLOCKv.client.request("POST", "/v1/publisher/face", {
            template: templatePayload.template,
            display_url: "native://image",
            package_url: ".",
            resources: [],
            constraints: {
                bluetooth_le: false,
                contact_list: false,
                gps: false,
                three_d: false,
                quality: "low",
                platform: "generic",
                view_mode: "icon"
            },
            config: {}
        }, true)

        // Register 3D face if needed
        if (!customFaces.find(f => f.constraints && f.constraints.view_mode == 'engaged')) if (vatomDoc.get('modelURL')) {

            await BLOCKv.client.request("POST", "/v1/publisher/face", {
                template: templatePayload.template,
                display_url: "native://generic-3d",
                package_url: ".",
                resources: [],
                constraints: {
                    bluetooth_le: false,
                    contact_list: false,
                    gps: false,
                    three_d: false,
                    quality: "low",
                    platform: "generic",
                    view_mode: "engaged"
                },
                config: {}
            }, true)

        }

        // If a card URL is specified, register a card face
        if (vatomDoc.get('cardAppURL')) {

            // Register the card app face
            if (!customFaces.find(f => f.constraints && f.constraints.view_mode == 'card')) await BLOCKv.client.request("POST", "/v1/publisher/face", {
                template: templatePayload.template,
                display_url: vatomDoc.get('cardAppURL'),
                package_url: ".",
                resources: [],
                constraints: {
                    bluetooth_le: false,
                    contact_list: false,
                    gps: false,
                    three_d: false,
                    quality: "low",
                    platform: "generic",
                    view_mode: "card"
                },
                config: {}
            }, true)

        } else if (vatomDoc.get('cardImageURL')) {

            // Register the card app face
            if (!customFaces.find(f => f.constraints && f.constraints.view_mode == 'card')) await BLOCKv.client.request("POST", "/v1/publisher/face", {
                template: templatePayload.template,
                display_url: 'native://image',
                package_url: ".",
                resources: [],
                constraints: {
                    bluetooth_le: false,
                    contact_list: false,
                    gps: false,
                    three_d: false,
                    quality: "low",
                    platform: "generic",
                    view_mode: "card"
                },
                config: {
                    image: 'CardImage',
                    scale: 'fill'
                }
            }, true)

        }

        // Register custom faces
        for (let customFace of customFaces) {

            // Add field defaults
            customFace = Object.assign({
                template: templatePayload.template,
                package_url: ".",
                resources: [],
                constraints: {},
                config: {}
            }, customFace)
            customFace.constraints = Object.assign({
                bluetooth_le: false,
                contact_list: false,
                gps: false,
                three_d: false,
                quality: "low",
                platform: "generic"
            }, customFace.constraints)

            // Register it
            await BLOCKv.client.request("POST", "/v1/publisher/face", customFace, true)

        }

        // Register transfer action
        if (vatomDoc.get('canTransfer')) await BLOCKv.client.request("POST", "/v1/publisher/action", {
            name: templatePayload.template + "::Action::Transfer",
            reactor: "blockv://v1/Transfer",
            wait: true,
            abort_on_pre_error: true,
            abort_on_main_error: true,
            guest_user: true,
            timeout: 10000,
            config: {
                auto_create_landing_page: "https://vatom.com/#",
                auto_create_mode: "claim",
                auto_create_non_existing_recipient: true
            },
            action_notification: {
                on: true,
                msg: "You received a vAtom",
                custom: {}
            }
        }, true)

        // Register clone action
        if (vatomDoc.get('canClone')) await BLOCKv.client.request("POST", "/v1/publisher/action", {
            name: templatePayload.template + "::Action::Clone",
            reactor: "blockv://v1/Clone",
            wait: true,
            abort_on_pre_error: true,
            abort_on_main_error: true,
            guest_user: true,
            timeout: 10000,
            config: {
                auto_create_landing_page: "https://vatom.com/#",
                auto_create_mode: "claim",
                auto_create_non_existing_recipient: true
            },
            action_notification: {
                on: true,
                msg: "You received a vAtom",
                custom: {}
            }
        }, true)

        // Register acquire action
        if (vatomDoc.get('canAcquire')) await BLOCKv.client.request("POST", "/v1/publisher/action", {
            name: templatePayload.template + "::Action::Acquire",
            reactor: "blockv://v1/AcquireWithCoins",
            wait: true,
            guest_user: true,
            abort_on_pre_error: true,
            abort_on_main_error: true,
            timeout: 10000,
        }, true)

        // Register acquire copy action
        if (vatomDoc.get('canAcquirePubVariation')) await BLOCKv.client.request("POST", "/v1/publisher/action", {
            name: templatePayload.template + "::Action::AcquirePubVariation",
            reactor: "blockv://v1/AcquirePubVariationWithCoins",
            wait: true,
            guest_user: true,
            abort_on_pre_error: true,
            abort_on_main_error: true,
            timeout: 10000,
        }, true)

        // Register AR post-reactor
        if (canBeAnchored) await BLOCKv.client.request("POST", "/v1/publisher/actionhandler", {
            name: templatePayload.template + "::ActionHandler::DropArAnchor",
            reactor: "https://us-central1-digital-objects-io.cloudfunctions.net/arAnchorReactor",
            timeout: 10000
        }, true)

        // Register drop action
        if (vatomDoc.get('canDrop')) await BLOCKv.client.request("POST", "/v1/publisher/action", {
            name: templatePayload.template + "::Action::Drop",
            reactor: "blockv://v1/Drop",
            wait: true,
            guest_user: true,
            abort_on_pre_error: true,
            abort_on_main_error: true,
            timeout: 10000,
            policy: {
                post: canBeAnchored ? [{
                    action_handler: templatePayload.template + "::ActionHandler::DropArAnchor"
                }] : []
            }
        }, true)

        // Register pickup action
        if (vatomDoc.get('canPickup')) await BLOCKv.client.request("POST", "/v1/publisher/action", {
            name: templatePayload.template + "::Action::Pickup",
            reactor: "blockv://v1/Pickup",
            wait: true,
            guest_user: true,
            abort_on_pre_error: true,
            abort_on_main_error: true,
            timeout: 10000,
        }, true)

        // Register redeem action
        if (vatomDoc.get('canRedeem')) await BLOCKv.client.request("POST", "/v1/publisher/action", {
            name: templatePayload.template + "::Action::Redeem",
            reactor: "blockv://v1/Redeem",
            wait: true,
            abort_on_pre_error: true,
            abort_on_main_error: true,
            timeout: 10000,
        }, true)

        // Register SetUserData custom action
        // await BLOCKv.client.request("POST", "/v1/publisher/actionhandler", {
        //     name: templatePayload.template + "::ActionHandler::DropArAnchor",
        //     reactor: "https://us-central1-digital-objects-io.cloudfunctions.net/arAnchorReactor",
        //     timeout: 10000
        // }, true)
        await BLOCKv.client.request("POST", "/v1/publisher/action", {
            name: templatePayload.template + "::Action::SetUserData",
            reactor: "https://us-central1-digital-objects-io.cloudfunctions.net/setUserData",
            wait: true,
            guest_user: true,
            abort_on_pre_error: true,
            abort_on_main_error: true,
            timeout: 10000,
        }, true)

        // Almost done, now we just need to register the variation
        await BLOCKv.client.request("POST", "/v1/template_variations", {
            template: templatePayload.template,
            template_variation: templatePayload.template + "::Single",
            auto_create_on_acquire: !!vatomDoc.get('canAcquirePubVariation'),
            vatom: {},
            unpublished: sample ? true : false,
            max_clones_per_instance: vatomDoc.get('maxClonesTotal') || 100,
            max_clones_total: vatomDoc.get('maxClonesTotal') || 2147483647,
            redeemable: vatomDoc.get('canRedeem') ? {
                org_change: {
                    redeemable: false
                }
            } : undefined
        }, true)

        // Store this variation ID on the document
        let variationID = templatePayload.template + "::Single"
        if (sample)
            await SessionManager.db.collection("vatoms").doc(vatomDoc.id).set({ sampleVariationID: variationID }, { merge: true })
        else
            await SessionManager.db.collection("vatoms").doc(vatomDoc.id).set({ variationID }, { merge: true })

        // Check if vatom is redeemable
        if (!sample && vatomDoc.get('canRedeem')) {

            // Add to list of all redeemable IDs
            let redeemableVariationIDs = vatomDoc.get('redeemableVariationIDs') || []
            redeemableVariationIDs.push(variationID)
            await SessionManager.db.collection("vatoms").doc(vatomDoc.id).set({ redeemableVariationIDs }, { merge: true })

            // Refresh list of redeemable vatoms on the backend
            if (vatomDoc.get('canRedeem'))
                await this.updateRedeemable()

        }

        // Done
        return variationID

    }

    /**
     *  Build the vatom from the firebase Vatom doc and return the template variation ID. 
     *  Call this when modifying the `canRedeem` field on the vatom template.
     */
    static async updateRedeemable() {

        // Fetch all redeemable vatoms
        let snapshot = await SessionManager.db.collection("vatoms")
            .where("owner", "==", SessionManager.firebaseUserID)
            .where("canRedeem", "==", true)
            .get()

        // Create an array containing all redeemable variation IDs
        let redeemables = []
        for (let doc of snapshot.docs)
            for (let id of doc.get('redeemableVariationIDs') || [])
                redeemables.push(id)

        // Do update
        console.log("Updating account redeemable variations:", redeemables)
        await BLOCKv.client.request('POST', `/v1/users/${BLOCKv.store.userID}/redeemables`, { redeemables }, true)

    }

}
