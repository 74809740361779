
/** The config object */
var Config = null

/**
 *  Allows sending analytics data to Google Analytics.
 */
export default class Analytics {

    /**
     *  Call this on app startup to register global functions. For convenience, this also registers the Analytics class
     *  as a global variable, the same way Google's gtag library does. This means you can call `Analytics.event()` etc
     *  without importing this class in every file.
     */
    static setup(config) {

        // Check if already setup
        if (Config)
            throw new Error('You should only call Analytics.config() once.')

        // Store config
        Config = config

        // Check for tracking ID
        if (!Config.trackingID)
            throw new Error('No Google Analytics tracking ID provided.')

        // Make sure anlytics is registered on the window
        if (!window.dataLayer) {
            window.dataLayer = window.dataLayer || [];
            window.gtag = function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());
            gtag('config', Config.trackingID)
        }

        // Register us as a global
        window.Analytics = Analytics

        // Dynamically load the gtag.js google library
        let elem = document.createElement('script')
        elem.src = 'https://www.googletagmanager.com/gtag/js?id=' + Config.trackingID
        document.body.appendChild(elem)

    }

    /** Log a screen view */
    static screen(name) {
        gtag('config', Config.trackingID, {'page_path': '/' + location.hash })
        Analytics.event('screen_view', { 'screen_name': name })
        Analytics.event('page_view')
    }

    /** Log an error */
    static error(error, extraFields = {}) {
        Analytics.event('exception', Object.assign({ description: error.message, fatal: false }, extraFields))
    }

    /** Log a login */
    static login(method = 'password') {
        Analytics.event('login', { method })
    }

    /** Log a user registration */
    static signUp(method = 'password') {
        Analytics.event('sign_up', { method })
    }

    /**
     *  Log a custom event. See this URL for known event types: https://developers.google.com/gtagjs/reference/event
     */
    static event(name, args = {}) {

        // Check config
        if (!Config)
            throw new Error('Please set up by calling Analytics.config() first.')

        // Log it
        gtag('event', name, args)
        console.log(`[Analytics] ${name}`)

    }

}
