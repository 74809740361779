
import "@babel/polyfill"
import React from 'react'
import ReactDOM from 'react-dom'
import { HashRouter as Router, Route, Switch } from 'react-router-dom'
import { asyncComponent } from 'react-async-component'
import SessionManager from './code/SessionManager'
import Analytics from "./code/Analytics"
import AppContainer from './shared/AppContainer'


/** Called on app startup */
document.addEventListener('DOMContentLoaded', async function() {

    // HACK: There's a bug in Parcel where images that are used in multiple routes sometimes fail to load
    // unless image is required in the first route. Work-around, just require it here.
    require('./routes/Merchant/do-qr-icon.png')
    require('./popups/TaskLogPopup/Spinner-1s-200px.apng')

    // Setup analytics
    Analytics.setup({ trackingID: "UA-129682975-1" })

    // Check session callback
    if (SessionManager.check())
        return;

    // Wait for session manager to log back into firebase
    await SessionManager.setup()

    // Check done, remove loader
    let loader = document.getElementById('loader')
    loader.parentNode.removeChild(loader)

    // Render React app
    var div = document.createElement('div')
    document.body.appendChild(div)
    ReactDOM.render(<AppContainer><Router><Switch>

        {/* App routes */}
        <Route path='/payment-success' component={asyncComponent({ resolve: () => import('./routes/PaymentSuccess'), LoadingComponent })} />
        <Route path='/vatom/:id' component={asyncComponent({ resolve: () => import('./routes/VatomEditor'), LoadingComponent })} />
        <Route path='/create-vatom' component={asyncComponent({ resolve: () => import('./routes/VatomCreator'), LoadingComponent })} />
        <Route path='/vatoms' component={asyncComponent({ resolve: () => import('./routes/Vatoms'), LoadingComponent })} />
        <Route path='/run-campaign/:vatomid' component={asyncComponent({ resolve: () => import('./routes/CampaignRunner'), LoadingComponent })} />
        <Route path='/run-campaign' component={asyncComponent({ resolve: () => import('./routes/CampaignRunner'), LoadingComponent })} />
        <Route path='/campaigns' component={asyncComponent({ resolve: () => import('./routes/Campaigns'), LoadingComponent })} />
        <Route path='/help' component={asyncComponent({ resolve: () => import('./routes/Help'), LoadingComponent })} />
        <Route path='/about' component={asyncComponent({ resolve: () => import('./routes/About'), LoadingComponent })} />
        <Route path='/merchant' component={asyncComponent({ resolve: () => import('./routes/Merchant'), LoadingComponent })} />
        <Route path='/merchant-redeem' component={asyncComponent({ resolve: () => import('./routes/MerchantRedeem'), LoadingComponent })} />
        <Route path='/redemption-monitor' component={asyncComponent({ resolve: () => import('./routes/RedemptionMonitor'), LoadingComponent })} />
        <Route component={asyncComponent({ resolve: () => import('./routes/Home'), LoadingComponent })} />

    </Switch></Router></AppContainer>, div)

})

/** Loading component */
const LoadingComponent = props => <div style={{ position: 'fixed', top: 0, left: 0, width: '100%', height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center', fontSize: 32, fontStyle: 'italic', fontWeight: 'bold', color: 'rgba(0, 0, 0, 0.1)' }}>Loading...</div>
