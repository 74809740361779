
import React from 'react'

export default class RadioButtonSegment extends React.Component {

    render = e => <div onClick={this.props.onClick} style={Object.assign({ display: 'inline-block', width: 300, margin: '16px', border: this.props.selected ? '1px solid #08F' : '1px solid #CCC', borderRadius: 8, position: 'relative', cursor: 'pointer' }, this.props.style)}>
    
        {/* Selected icon */}
        <div style={{ position: 'absolute', top: 16, left: 16, width: 16, height: 16, border: this.props.selected ? '2px solid #08F' : '2px solid #CCC', borderRadius: '50%' }}>
            <div style={{ opacity: this.props.selected ? '1' : '0', position: 'absolute', top: 4, left: 4, width: 'calc(100% - 8px)', height: 'calc(100% - 8px)', backgroundColor: '#08F', borderRadius: '50%' }} />
        </div>

        {/* Title */}
        <div style={{ margin: '16px 16px 4px 48px', fontSize: 13, fontWeight: 'bold', color: '#444', textAlign: 'left' }}>{this.props.title}</div>

        {/* Text */}
        <div style={{ margin: '0px 16px 16px 48px', fontSize: 12, fontWeight: 'normal', color: '#666', textAlign: 'left' }}>{this.props.text}</div>
    
    </div>

}