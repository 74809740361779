
import JSAlert from 'js-alert'
import Analytics from './Analytics';

/**
 *  Wrap your code in this function to show a "Please wait..." popup while it runs, as well as to show any errors to
 *  the user.
 *
 *  ```
 *  myFunction() { showBusy(async hide => {
 *
 *      // my code
 *
 *
 *  })}
 */
export function showBusy(code) {

    // Display loader
    let loader = JSAlert.loader('Please wait...')

    // Create loader dismisser
    const dismisser = e => loader.dismiss()

    // Start promise chain, call target code
    return Promise.resolve().then(e => code(dismisser)).then(e => {

        // All done, hide loader
        loader.dismiss()

    }).catch(err => {

        // Failed! Hide loader
        loader.dismiss()

        // Check if cancelled
        if (err == 'cancelled')
            return

        // Show error
        console.warn(err)
        JSAlert.alert(err.message || 'An unknown error occurred.', 'Error', JSAlert.Icons.Failed)

        // Send analytics
        Analytics.error(err)

    })

}
