
import React from 'react'
import Popup from '../Popup'
import Button from '../../shared/Button'
import Analytics from '../../code/Analytics';

export default class AccountUpgradePopup extends Popup {

    constructor() {
        super()

        // Setup state
        this.state = {}

    }

    componentDidMount() {

        // Send analytics
        Analytics.screen("Account Upgrade Popup")

    }

    render() {

        return <React.Fragment>

            <div style={{ margin: 40, marginBottom: 20, fontSize: 21, textAlign: 'center', color: '#444' }}>
                <b>Upgrade your account</b>
            </div>

            <div style={{ margin: 40, marginTop: 0, marginBottom: 20, fontSize: 13, textAlign: 'center', color: '#444', lineHeight: '1.5' }}>
                In order to create vAtoms, you will need to upgrade your BLOCKv account to a publisher account.
            </div>

            <Step number="1" style={{ lineHeight: '1.5', fontSize: 13 }}>
                <b>Login to developer.blockv.io</b><br/>
                <a href='https://developer.blockv.io' target='_blank'>Click here</a> to open the BLOCKv developer portal now.
            </Step>

            <Step number="2" style={{ lineHeight: '1.5', fontSize: 13 }}>
                <b>Upgrade your account</b><br/>
                Once logged in, select your profile icon in the top right, and select Apps.
            </Step>

            <Step number="3" style={{ lineHeight: '1.5', fontSize: 13 }}>
                <b>Follow the instructions</b><br/>
                Once you've filled in your information and upgraded your account, return here.
            </Step>

        </React.Fragment>

    }

}


const Step = props => <div style={{ margin: '40px 60px', display: props.hidden ? 'none' : 'flex', alignItems: 'flex-start' }}>

    {/* Step icon */}
    <div style={{ flex: '0 0 auto', marginRight: 20, width: 48, height: 38, paddingTop: 10, borderRadius: '50%', backgroundColor: 'rgba(0, 0, 0, 0.1)', color: '#AAA', fontSize: 24, textAlign: 'center' }}>{props.number}</div>

    {/* Content */}
    <div style={Object.assign({ flex: '1 1 auto' }, props.style)}>
        {props.children}
    </div>

</div>