
import React from 'react'

export default class Button extends React.Component {

    constructor() {
        super()

        // Setup state
        this.state = {}
        this.state.hovering = false

    }

    render() {

        // Apply default style
        var style = {
            display: 'inline-block',
            padding: '10px 20px',
            fontSize: 13,
            color: '#666',
            border: this.state.hovering ? '1px solid #08F' : '1px solid rgba(0, 0, 0, 0.1)',
            borderRadius: 3,
            cursor: 'pointer',
            margin: 10,
            backgroundColor: '#FFF'
        }

        // Apply active style
        if (this.props.active) Object.assign(style, {
            color: '#FFF',
            border: this.state.hovering ? 'rgba(0, 128, 255, 0.8)' : 'rgba(0, 128, 255, 1.0)',
            backgroundColor: this.state.hovering ? 'rgba(0, 128, 255, 0.8)' : 'rgba(0, 128, 255, 1.0)'
        })

        // Apply disablbed style
        if (this.props.disabled) Object.assign(style, {
            cursor: 'default',
            color: 'rgba(0, 0, 0, 0.1)',
            border: '1px solid rgba(0, 0, 0, 0.1)',
            backgroundColor: 'rgba(0, 0, 0, 0.05)'
        })

        // Render button
        return <div style={Object.assign(style, this.props.style)}
            onClick={this.props.onClick}
            onMouseOver={e => this.setState({ hovering: true })}
            onMouseOut={e => this.setState({ hovering: false })}
        >
            {this.props.text}
        </div>

    }

}
