
import SessionManager from './SessionManager'

/**
 *  Represents a newly running task in the database.
 */
export default class Task {

    constructor() {

        this.id = ''

    }

    /**
     *  Call this before doing anything else with the task. Sets up the task in the database.
     *
     *  @returns {Promise}
     */
    async setup(name = 'Task', campaign = null) {

        // Create task
        let doc = await SessionManager.db.collection('tasks').add({
            name,
            owner: SessionManager.firebaseUserID,
            date: new Date(),
            campaign
        })

        // Store ID
        this.id = doc.id
        console.log('Created task: ' + this.id)

    }

    setName(name) {
        return SessionManager.db.collection('tasks').doc(this.id).set({ name }, { merge: true })
    }

    /**
     *  Adds a log entry to the task.
     *
     *  @param {string} target The email address, phone number, geo pos coordinate etc.
     *  @param {string} type One of 'success', 'failed' for targets, or 'info', 'warn' for non-target log messages
     *  @param {string} text Any text to log
     */
    log(target, type, text) {

        // Log to console as well
        console.log(`[Task] Id=${this.id} Type=${type} Target=${target} Text=${text}`)

        // Write to database
        SessionManager.db.collection('tasks').doc(this.id).collection('logs').add({
            target: target || '',
            type,
            text,
            date: new Date(),
            owner: SessionManager.firebaseUserID
        })

    }

    /** 
     * Sets the custom action
     */
    setAction(type, name, data) {

        // Store it
        return SessionManager.db.collection('tasks').doc(this.id).set({ 
            action: {
                type,
                name,
                data
            } 
        }, { merge: true })

    }

}
