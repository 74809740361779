
import React from 'react'
import SessionManager from '../code/SessionManager'
import BLOCKv from '../code/BLOCKv'
import VEEPopup from '../popups/VEEPopup'
import AccountUpgradePopup from '../popups/AccountUpgradePopup'
import { Loader } from '../code/Decorators'

export default class AppBar extends React.Component {

    constructor() {
        super()

        // Setup state
        this.state = {}
        this.state.menuOpen = false
        this.state.username = ''
        this.state.vee = 0
        this.state.isAdmin = false

    }

    componentDidMount() {

        // Refresh user info
        this.fetchProfileInfo()

    }

    render() {

        return <div style={{ position: 'fixed', top: 0, left: 0, width: '100%', height: 88, zIndex: '10', display: 'flex', alignItems: 'center', backgroundColor: 'rgba(0, 0, 0, 0.25)', color: '#FFF' }}>

            {/* Logo */}
            <img src={require('./do-logo.js')} style={{ marginLeft: '15%' }} />
            <div style={{ marginLeft: 6 }}>DigitalObjects</div>

            {/* Padding */}
            <div style={{ flex: '1 1 auto' }} />

            {/* Home tab */}
            <Tab selected={this.props.tab == 'home'} onClick={e => location.hash = '/'} title='Home' />
            
            {/* If logged in, show tabs */}
            {SessionManager.loggedIn ? <React.Fragment>

                <Tab selected={this.props.tab == 'vatoms'} onClick={e => location.hash = '/vatoms'} title='vAtoms' />
                <Tab selected={this.props.tab == 'run-campaign'} onClick={e => location.hash = '/run-campaign'} title='Distribute' />
                <Tab selected={this.props.tab == 'campaigns'} onClick={e => location.hash = '/campaigns'} title='History' />
                <Tab selected={this.props.tab == 'merchant'} onClick={e => location.hash = '/merchant'} title='Merchant' />

            </React.Fragment> : null}

            {/* Help tabs */}
            <Tab selected={this.props.tab == 'help'} onClick={e => location.hash = '/help'} title='FAQ' />
            <Tab selected={this.props.tab == 'about'} onClick={e => location.hash = '/about'} title='About' />
            
            {/* Avatar icon, or login button if not logged in */}
            {SessionManager.loggedIn ?
                <div onClick={e => this.setState({ menuOpen: true })} style={{ margin: '0px 15% 0px 12px', width: 32, height: 32, borderRadius: '50%', backgroundColor: 'rgba(255, 255, 255, 0.3)', cursor: 'pointer', backgroundSize: 'cover', backgroundPosition: 'center', backgroundRepeat: 'no-repeat', backgroundImage: 'url(' + this.state.avatarURL + ')' }} />
             :
                <div style={{ color: '#FFF', padding: '10px 20px', margin: '0px 15% 0px 10px', cursor: 'pointer', fontSize: 12, backgroundColor: '#5AABFA', borderRadius: 4 }} onClick={e => this.login()}>Login</div>
            }

            {/* Menu overlay background */}
            <div onClick={e => this.setState({ menuOpen: false })} style={{ position: 'fixed', top: 0, left: 0, width: '100%', height: '100%', transition: 'display 0.25s, opacity 0.25s', display: this.state.menuOpen ? 'block' : 'none', opacity: this.state.menuOpen ? '0.1' : '0.0', backgroundColor: '#000' }} />

            {/* Menu */}
            <div style={{ position: 'fixed', top: 70, right: 20, transform: this.state.menuOpen ? 'translateX(0px)' : 'translateX(320px)', transition: 'transform 0.25s', width: 280, backgroundColor: '#FAFAFA', borderRadius: 3, boxShadow: '0px 1px 2px rgba(0, 0, 0, 0.2)', color: '#444' }}>

                {/* User information */}
                <div style={{ margin: '12px 12px', fontSize: 17, fontWeight: 'bold' }}>{this.state.username || 'Loading...'}</div>

                {/* Actions */}
                {this.state.isAdmin ? 
                    <MenuItem title='VEE Wallet' onClick={e => this.addVEE()} />
                : 
                    <MenuItem title='Upgrade to Publisher' onClick={e => AccountUpgradePopup.show()} />
                }
                <MenuItem title='Logout' onClick={e => this.logout()} />

                {/* App version */}
                <div style={{ margin: '8px 12px', color: '#888',  fontSize: 12 }}>Version {require('../../package.json').version} (beta)</div>

            </div>

        </div>

    }

    /**
     *  Called when the user clicks the Login button
     */
    @Loader async login() {

        // Do login
        await SessionManager.login()

        // Refresh UI
        this.forceUpdate()

        // Go to the campaigns screen
        location.hash = '/campaigns'

        // Refresh user info
        this.fetchProfileInfo()

    }

    /**
     *  Called when the user clicks the logout button in the menu
     */
    async logout() {

        // Close menu
        this.setState({ menuOpen: false, username: '', vee: 0 })

        // Do logout
        await SessionManager.logout()

        // Refresh UI
        this.forceUpdate()

        // Go to home screen
        location.hash = '/'

    }

    /**
     *  Fetches the user's name and VEE count
     */
    async fetchProfileInfo() {

        // Stop if not logged in
        if (!SessionManager.loggedIn)
            return

        // Get user name
        let user = await BLOCKv.UserManager.getCurrentUser()
        this.setState({
            username: (user.firstName + ' ' + user.lastName).trim(),
            avatarURL: BLOCKv.UserManager.encodeAssetProvider(user.avatarUri),
            isAdmin: user.isAdmin
        })

    }

    /** Show the Add VEE popup */
    async addVEE() {

        // Show popup
        await VEEPopup.show()

    }

}


/** Menu item */
class MenuItem extends React.Component {

    constructor() {
        super()

        // Setup state
        this.state = {}
        this.state.hovering = false

    }

    render() {

        return <div style={Object.assign({ 
            padding: '8px 12px', 
            color: '#444', 
            cursor: 'pointer', 
            backgroundColor: this.state.hovering ? 'rgba(0, 0, 0, 0.1)' : 'rgba(0, 0, 0, 0.0)',
            fontSize: 15 
        }, this.props.style)}

        onClick={this.props.onClick}
        onMouseOver={e => this.setState({ hovering: true })}
        onMouseOut={e => this.setState({ hovering: false })}>
        
            {this.props.title}
            
        </div>

    }

}

// Tab component
const Tab = props => <div style={{ padding: '5px 10px', margin: '0px 10px', fontSize: 12, fontWeight: 'bold', color: '#FFF', cursor: 'pointer', borderBottom: props.selected ? '2px solid #5AABFA' : '2px solid transparent' }} onClick={props.onClick}>{props.title}</div>